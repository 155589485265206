import { gql, useQuery } from "@apollo/client/index.js";
import { useMemo } from "react";
import { Avatar, Section } from "sparta";
import loadingAnimation from "~/assets/loading.gif";

// This query gets the users who liked the post we are doing this directly in client side now
const GetLikedUsersList = gql`
  query GetLikedUsers($postId: ID!) {
    interactions(
      where: { type: { equals: "like" }, post: { id: { equals: $postId } } }
    ) {
      user {
        id
        fullName
        jobTitle
        profilePicture {
          url
        }
      }
    }
  }
`;

type LikedUser = {
  id: string;
  fullName: string;
  jobTitle: any;
  profilePicture: { url: string };
};
interface InteractionListProps {
  postId: string;
}

export function InteractionList({ postId }: InteractionListProps) {
  const { data, loading } = useQuery(GetLikedUsersList, {
    variables: { postId },
  });
  const interactions = useMemo(() => data?.interactions || [], [data]);

  return (
    <Section>
      {loading ? (
        <img className="loading-animation" src={loadingAnimation} alt="Loading..." />
      ) : interactions.length > 0 ? (
        interactions.map(({ user }: { user: LikedUser }) => (
          <div key={user.id} className="liked-user">
            <Avatar src={user?.profilePicture?.url || ""} fallback={user.fullName || "User"} />
            <div className="liked-user-details">
              {user?.fullName}
              <span>{user?.jobTitle}</span>
            </div>
          </div>
        ))
      ) : (
        <div>No likes yet</div>
      )}
    </Section>
  );
}
