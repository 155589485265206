import { Link } from "@remix-run/react";
import type { Hit } from "instantsearch.js";
import { useAtom } from "jotai";
import { useState } from "react";
import { Highlight } from "react-instantsearch";
import { Badge, Button, Card, Flex, GridV2, Heading, ModalV2 } from "sparta";
import { LoginModal } from "~/components/layout/LoginModal";
import { exportModeAtom } from "~/utils/atoms";
import useResumeablePath from "~/utils/hooks/useResumeablePath";
import type { IPostHit } from "../../types";
import InsightsAndActions from "./InsightsAndActions";
import { Interactions } from "./Interactions";
import { InteractionList } from "./Interactions/InteractionList";
import { TagGroup } from "./TagGroup";
import Thumbnail from "./Thumbnail";

interface PostHitProps {
  hit: Hit<IPostHit>;
  selected?: boolean;
  onSelect?: () => void;
  compact?: boolean;
  withHighlight?: boolean;
}

export function PostHit({ hit, selected, onSelect, compact, withHighlight = true }: PostHitProps) {
  const {
    id,
    slug,
    publishedAt,
    featured,
    tags,
    agency,
    thumbnailUrl,
    thumbnail,
    platforms,
    userLikes,
    userBookmarks,
  } = hit;
  const [exportMode] = useAtom(exportModeAtom);
  const backPath = useResumeablePath();
  const [showAllModal, setShowAllModal] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showInteractionsModal, setShowInteractionsModal] = useState<boolean>(false);

  // Login Modal description
  const loginDescription: string =
    "Like what you see? Login or Sign-up to show your appreciation and join the conversation!";

  // Converting the timestamp from seconds to milliseconds
  const milliseconds = publishedAt * 1000;

  // Creating a new Date object using the milliseconds
  const date = new Date(milliseconds);

  // Formating the date
  const formattedPublishedAt = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

  // get the return path
  const postLink = `/${platforms[0]?.toLowerCase()}/posts/${slug}`;

  const tagList =
    tags?.map((tag) => (
      <Badge key={`${id}-${tag}`} color="green" variant="solid" asChild>
        {tag}
      </Badge>
    )) || [];

  if (agency) {
    tagList?.unshift(
      <Badge key={agency} color="green" variant="solid">
        {agency}
      </Badge>,
    );
  }

  // determine if the post requires a featured tag
  if (featured) {
    tagList?.unshift(
      <Badge key={tags.length} color="cyan">
        Featured
      </Badge>,
    );
  }

  const openCloseMoreTagsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllModal(!showAllModal);
  };

  const handleInteractionClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const openCloseLoginModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowLoginModal(!showLoginModal);
  };

  const openInteractionsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowInteractionsModal(!showInteractionsModal);
  };

  const handleSelect = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect();
  };

  return (
    <>
      <Card asChild>
        <Link to={postLink} className="nostyle">
          <GridV2 columns="120px 1fr" gap={"1rem"} minHeight="100%">
            <Thumbnail image={thumbnailUrl || thumbnail?.url} link={postLink} />
            <Flex
              direction="column"
              justify="between"
              align={"start"}
              minWidth={"100%"}
              width={"100%"}
              height="100%"
              gap="1"
            >
              <Flex direction="column" gap="5px">
                <InsightsAndActions
                  formattedPublishedAt={formattedPublishedAt}
                  hit={hit}
                  showExport={false}
                  compact={compact}
                  selected={selected}
                  onSelect={handleSelect}
                />
                <Heading
                  size="4"
                  weight="bold"
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    lineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "break-spaces"
                  }}
                >
                  {withHighlight ? <Highlight attribute="title" hit={hit} /> : hit.title}
                </Heading>
              </Flex>
              <Flex>
                {platforms.map((platform) => (
                  <Badge key={platform}>{platform}</Badge>
                ))}
                <TagGroup placeholder={false} tags={tagList} openCloseMoreTagsModal={openCloseMoreTagsModal} />
              </Flex>
              <Flex width={"100%"} justify="start" align={"center"}>
                <Interactions
                  postId={id}
                  initialLikes={userLikes}
                  initialBookmarks={userBookmarks}
                  onInteractionClick={handleInteractionClick}
                  setShowLoginModal={openCloseLoginModal}
                />
              </Flex>
            </Flex>
          </GridV2>
        </Link>
      </Card>
      <ModalV2.Root open={showAllModal} onOpenChange={openCloseMoreTagsModal}>
        <ModalV2.Content>
          <ModalV2.Title>All tags</ModalV2.Title>
          <Flex wrap="wrap">
            {tags.map((item) => (
              <Badge key={item}>{item}</Badge>
            ))}
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <ModalV2.Close>
              <Button onClick={openCloseMoreTagsModal} variant="soft">
                Close
              </Button>
            </ModalV2.Close>
          </Flex>
        </ModalV2.Content>
      </ModalV2.Root>

      <ModalV2.Root open={showInteractionsModal} onOpenChange={openInteractionsModal}>
        <ModalV2.Content>
          <ModalV2.Title>All Likes</ModalV2.Title>
          <Flex wrap="wrap">
            <InteractionList postId={id} />
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <ModalV2.Close>
              <Button onClick={openInteractionsModal} variant="soft">
                Close
              </Button>
            </ModalV2.Close>
          </Flex>
        </ModalV2.Content>
      </ModalV2.Root>

      <LoginModal open={showLoginModal} toggleModal={openCloseLoginModal} description={loginDescription} />
    </>
  );
}
