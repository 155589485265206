import { Link } from "@remix-run/react";
import { Button, Dropdown, Menu, Option } from "sparta";

interface IMoreButton {
  postId: string;
  showExport?: boolean;
}

export default function MoreButton({ postId, showExport }: IMoreButton) {
  // decide whether or not to render more button
  const renderMoreButton = showExport;

  return renderMoreButton ? (
    <Dropdown alignment="end" className="more-button" hideArrow>
      <Button variant="outline" theme="secondary">
        ...
      </Button>
      <Menu label="more-menu" width="140px" style={{ zIndex: 300 }}>
        {showExport ? renderOption("Export to PDF", `../?exportIds=${postId}&exportType=Pdf`) : null}
        {showExport ? renderOption("Export to CSV", `../?exportIds=${postId}&exportType=Csv`) : null}
      </Menu>
    </Dropdown>
  ) : null;
}

/**
 * Renders the option in an expected format
 *
 * @param optionText the text to be rendered in place of the option
 * @param href the href to be used for the link
 * @returns the rendered option
 */
const renderOption = (optionText: string, href: string) => (
  <Option
    wrap={(option) => (
      <Link
        to={href}
        style={{
          textDecoration: "none",
          color: "var(--black)",
        }}
      >
        {option}
      </Link>
    )}
  >
    {optionText}
  </Option>
);
